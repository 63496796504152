import React, { useRef, useState } from "react";
import Frame2txt from "../assets/Frame2/Frame2txt.png";
import Frame2girl from "../assets/Frame2/Frame2girl.png";
import Frame2CTA from "../assets/Frame2/Frame2CTA.png";
import Frame2bottontxt from "../assets/Frame2/Frame2bottontxt.png";

function Frame2({ onNextClick, onFileSelect }) {
  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(URL.createObjectURL(file));
    onFileSelect(URL.createObjectURL(file));
    onNextClick();
  };

  const handleClickOnImage = () => {
    fileInputRef.current.click();
  };

  console.log(selectedFile);

  return (
    <div className=" w-11/12 h-full flex flex-col justify-center   items-center ">
      <img src={Frame2txt} alt="" />
      <img className="w-3/4" src={Frame2girl} alt="" />

      <img
        className="w-3/4 mt-4 mb-8"
        id="frame2-cta"
        onClick={handleClickOnImage}
        src={Frame2CTA}
        alt=""
      />
      <input
        className="hidden"
        ref={fileInputRef}
        onChange={handleFileChange}
        type="file"
        id="uploadPhotoInput"
        accept="image/*"
      />

      <img src={Frame2bottontxt} className="w-3/4" alt="" />
    </div>
  );
}

export default Frame2;
