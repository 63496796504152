import React, { useRef, useState } from "react";
import html2canvas from "html2canvas";
import cardbg from "../assets/FinalCard/cardbg.png";
import cardbottombannera from "../assets/FinalCard/cardbottombannera.png";
import cardbottombannerb from "../assets/FinalCard/cardbottombannerb.png";
import cardbottombannerc from "../assets/FinalCard/cardbottombannerc.png";
import Cardgirl from "../assets/FinalCard/Cardgirl.png";
import frame3txt from "../assets/Frame3/frame3txt.png";
import frame3text2 from "../assets/Frame3/frame3text2.png";
import frame3CTA from "../assets/Frame3/frame3CTA.png";
import skucard4 from "../assets/Frame3/skucard4.png";
import skucardBtn1 from "../assets/Frame3/SKU_btn1.png";
import skucardBtn2 from "../assets/Frame3/SKU_btn2.png";
import skucard3 from "../assets/Frame3/skucard3.png";
import skucard2 from "../assets/Frame3/skucard2.png";
import skucard1 from "../assets/Frame3/skucard1.png";
import { registerTag } from "../helper/registerTag";

function Frame3({ onNextClick, displayImg, selectedOption }) {
  const canvasRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setDimensions({ width: naturalWidth, height: naturalHeight });
  };

  const handleDownloadClick = () => {
    registerTag("Download Clicked");

    if (canvasRef.current) {
      html2canvas(canvasRef.current).then((canvas) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = canvas.toDataURL("image/png");
        downloadLink.download = "downloaded-image.png";
        downloadLink.click();
      });
    }
  };

  return (
    <div className="h-full w-full ">
      {" "}
      <div className="flex h-4/6 justify-center items-center mt-10">
        {/* <img id="Frame3Usercard" src="assets\Frame3\Frame3Usercard.png" alt="" />  */}
        <div
          ref={canvasRef}
          className="w-1/2 flex relative  h-[320px] ml-4"
          id="frame3-pic-container"
        >
          {/* <canvas id="canvas" ></canvas> */}
          <img
            id="uploadedImage-clone"
            alt="Uploaded Image"
            onLoad={handleImageLoad}
            src={displayImg}
            className={`absolute z-10 left-0 max-w-[150px] max-h-[150px]  bottom-[44%] ${
              dimensions.height > dimensions.width ? "ml-6" : ""
            } `}
          />
          <img className="absolute bottom-0 " src={cardbg} alt="" />
          <img
            className="absolute z-30 bottom-0"
            src={
              selectedOption === "optiona"
                ? cardbottombannera
                : selectedOption === "optionb"
                ? cardbottombannerb
                : cardbottombannerc
            }
            alt=""
          />
          <img
            className="absolute bottom-14 w-4/6 right-0 z-20"
            src={Cardgirl}
            alt=""
          />
        </div>
        <div className="w-1/2 ">
          <img src={frame3txt} alt="" />
          <img src={frame3text2} alt="" />
          <img
            id="frame3-cta"
            src={frame3CTA}
            onClick={handleDownloadClick}
            alt=""
          />
        </div>
      </div>
      <div className="bg-[url('/src/assets/Frame3/frame3bottomBG.png')] w-full   flex absolute bottom-0 py-6  left-0 overflow-auto items-center">
        <LinkComponent
          img={skucard1}
          btn1={skucardBtn1}
          btn2={skucardBtn2}
          link1={
            "https://shopee.co.id/10-Pcs-Indomie-HypeAbis-Ayam-Geprek-i.133304525.2110434449?sp_atk=4829735a-6ae8-48c5-b01e-73983cdd44c6&xptdk=4829735a-6ae8-48c5-b01e-73983cdd44c6"
          }
          link2={
            "https://www.tokopedia.com/indomieofficial/10-pcs-indomie-hypeabis-ayam-geprek?extParam=src%3Dshop%26whid%3D4312764"
          }
          link3={
            "https://shopee.co.id/10-Pcs-Indomie-HypeAbis-Ayam-Geprek-i.133304525.2110434449?sp_atk=4829735a-6ae8-48c5-b01e-73983cdd44c6&xptdk=4829735a-6ae8-48c5-b01e-73983cdd44c6"
          }
        />
        <LinkComponent
          img={skucard2}
          btn1={skucardBtn1}
          btn2={skucardBtn2}
          link1={
            "https://shopee.co.id/10-Pcs-Indomie-HypeAbis-Rasa-Seblak-Hot-Jeletot-i.133304525.3916021779?sp_atk=9f2776dd-7240-4c80-ba4f-ae9eda11c9b3&xptdk=9f2776"
          }
          link2={
            "https://www.tokopedia.com/indomieofficial/10-pcs-indomie-hypeabis-rasa-seblak-hot-jeletot?extParam=src%3Dshop%26whid%3D4312764"
          }
          link3={
            "https://shopee.co.id/10-Pcs-Indomie-HypeAbis-Rasa-Seblak-Hot-Jeletot-i.133304525.3916021779?sp_atk=9f2776dd-7240-4c80-ba4f-ae9eda11c9b3&xptdk=9f2776dd-7240-4c80-ba4f-ae9eda11c9b3"
          }
        />
        <LinkComponent
          img={skucard3}
          btn1={skucardBtn1}
          btn2={skucardBtn2}
          link1={
            "https://shopee.co.id/5-Pcs-Indomie-HypeAbis-Ayam-Geprek-i.133304525.2104246715?sp_atk=40541c7f-15a2-4367-a225-ee50f729ca30&xptdk=40541c7f-15a2-4367-a225-ee50f729ca30"
          }
          link2={
            "https://www.tokopedia.com/indomieofficial/5-pcs-indomie-hypeabis-ayam-geprek?extParam=src%3Dshop%26whid%3D4312764"
          }
          link3={
            "https://shopee.co.id/5-Pcs-Indomie-HypeAbis-Ayam-Geprek-i.133304525.2104246715?sp_atk=40541c7f-15a2-4367-a225-ee50f729ca30&xptdk=40541c7f-15a2-4367-a225-ee50f729ca30"
          }
        />
        <LinkComponent
          img={skucard4}
          btn1={skucardBtn1}
          btn2={skucardBtn2}
          link1={
            "https://shopee.co.id/5-Pcs-Indomie-HypeAbis-Rasa-Seblak-Hot-Jeletot-i.133304525.17174809773?sp_atk=73f54cd6-39b6-433c-a550-fa9ce688f822&xptdk=73f54cd"
          }
          link2={
            "https://www.tokopedia.com/indomieofficial/5-pcs-indomie-hypeabis-rasa-seblak-hot-jeletot?extParam=src%3Dshop%26whid%3D4312764"
          }
          link3={
            "https://shopee.co.id/5-Pcs-Indomie-HypeAbis-Rasa-Seblak-Hot-Jeletot-i.133304525.17174809773?sp_atk=73f54cd6-39b6-433c-a550-fa9ce688f822&xptdk=73f54cd6-39b6-433c-a550-fa9ce688f822"
          }
        />
        {/* <img
          className="w-[110px]  mx-2"
          src={skucard1}
          alt=""
          onClick={() => {
     
            registerTag("SKU1 clicked");
          }}
        />
        <img
          className="w-[110px]  mx-2"
          src={skucard2}
          alt=""
          onClick={() => {
            registerTag("SKU2 clicked");
          }}
        />
        <img
          className="w-[110px]  mx-2"
          src={skucard3}
          alt=""
          onClick={() => {
            registerTag("SKU3 clicked");
          }}
        />
        <img
          className="w-[110px]  mx-2"
          src={skucard4}
          alt=""
          onClick={() => {
            registerTag("SKU4 clicked");
          }} 
        />*/}
      </div>
    </div>
  );
}

function LinkComponent({ img, btn1, btn2, link1, link2, link3 }) {
  const redirectToExternalUrl = (url) => {
    window.location.href = url;
  };
  return (
    <div className="min-w-[180px]  relative   min-h-[110px]  cursor-pointer  mx-1">
      <img
        className="absolute w-full h-full "
        src={img}
        alt=""
        onClick={() => {
          // registerTag("SKU4 clicked");
          redirectToExternalUrl(link1);
        }}
      />
      <img
        className="absolute z-10 left-2 bottom-2 w-[80px] cursor-pointer"
        src={btn1}
        alt=""
        onClick={() => {
          // registerTag("SKU4 clicked");
          redirectToExternalUrl(link2);
        }}
      />
      <img
        className="absolute z-10 right-2 bottom-2 w-[80px] cursor-pointer"
        src={btn2}
        alt=""
        onClick={() => {
          // registerTag("SKU4 clicked");
          redirectToExternalUrl(link3);
        }}
      />
    </div>
  );
}

export default Frame3;
