import React, { useRef, useState } from "react";
import frame2bCTA1 from "../assets/Frame2b/frame2bCTA1.png";
import frame2bCTA2 from "../assets/Frame2b/frame2bCTA2.png";

import { registerTag } from "../helper/registerTag";

function Frame2b({ onNextClick, displayImg, onFileSelect }) {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(URL.createObjectURL(file));
    onFileSelect(URL.createObjectURL(file));
  
  };

  const handleClickOnImage = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      {" "}
      <img
        id="uploadedImage"
        className="uploadedImage"
        width="100%"
        alt="Uploaded Image"
        src={displayImg}
      />
      <div className="flex w-full  bottom-0 absolute left-0">
        <img
          id="frame2b-cta1"
          onClick={handleClickOnImage}
          className="w-1/2"
          src={frame2bCTA1}
          alt=""
        />
        <input
          className="hidden"
          ref={fileInputRef}
          onChange={handleFileChange}
          type="file"
          id="uploadPhotoInput"
          accept="image/*"
        />
        <img
          onClick={()=>{
            registerTag("Upload image success")


  
            onNextClick()}}
          id="frame2b-cta2"
          className="w-1/2"
          src={frame2bCTA2}
          alt=""
        />
      </div>
    </div>
  );
}

export default Frame2b;
