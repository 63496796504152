import React from "react";
import Frame1videoplaceholder from "../assets/Frame1/Frame1videoplaceholder.png";
import Frame1textMiddle from "../assets/Frame1/Frame1textMiddle.png";
import Frame1girl from "../assets/Frame1/Frame1girl.png";
import Frame1CTA from "../assets/Frame1/Frame1CTA.png";
import frame1Video from "../assets/video/indomiehot.ymtads.com.mp4";


function Frame1({ onNextClick }) {
  return (
    <div className=" w-10/12 my-2 flex flex-col justify-center items-center">
      <div className="flex relative p-2  w-full justify-center items-center  ">
        <img
          src={Frame1videoplaceholder}
          className="-z-1 "
          alt="video_placeholder"
        />
        <video
          src={frame1Video}
          className=" absolute w-[92%] "
          loop
          autoPlay
          muted
          controls
        />
      </div>

      <img className="my-2" src={Frame1textMiddle} alt="frame1_middleText" />
      <img className="Frame1girl" src={Frame1girl} alt="frame1_girl" />
      <img
        onClick={()=>{
   
          onNextClick()}}
        className="w-1/2"
        id="frame1-cta"
        src={Frame1CTA}
        alt="frame1_cta"
      />
    </div>
  );
}

export default Frame1;
