import { useState } from "react";
import Frame1 from "./components/Frame1";
import Frame2 from "./components/Frame2";
import Frame2b from "./components/Frame2b";
import Frame2c from "./components/Frame2c";
import Frame3 from "./components/Frame3";
import pagebg from "../src/assets/Pagebg/pagebg.png";

function App() {
  const [currentFrame, setCurrentFrame] = useState(1);
  const [imgFile, setImageFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleButtonClick = () => {
    setCurrentFrame(currentFrame + 1);
  };

  const handleFileSelect = (selectedFile) => {
    setImageFile(selectedFile);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="w-full h-screen flex flex-col justify-center p-0 m-0  items-center">
      {currentFrame === 0 ? (
        <Frame2 onNextClick={handleButtonClick} />
      ) : currentFrame === 1 ? (
        <Frame2
          onNextClick={handleButtonClick}
          onFileSelect={handleFileSelect}
        />
      ) : currentFrame === 2 ? (
        <Frame2b
          onNextClick={handleButtonClick}
          displayImg={imgFile}
          onFileSelect={handleFileSelect}
        />
      ) : currentFrame === 3 ? (
        <Frame2c onNextClick={handleButtonClick} onOptionSelect={handleOptionSelect} />
      ) : currentFrame === 4 ? (
        <Frame3 onNextClick={handleButtonClick} displayImg={imgFile} selectedOption={selectedOption} />
      ) : null}
      <img className="-z-10 absolute  w-full h-screen" src={pagebg} alt="" />
    </div>
  );
}

export default App;
