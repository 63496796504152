import React, { useState } from "react";
import optiona from "../assets/Frame2c/optiona.png";
import optionb from "../assets/Frame2c/optionb.png";
import optionc from "../assets/Frame2c/optionc.png";
import Frame2cCTA from "../assets/Frame2c/Frame2cCTA.png";
import Frame2ctxtTitle from "../assets/Frame2c/Frame2ctxtTitle.png";
import { registerTag } from "../helper/registerTag";

function Frame2c({ onNextClick, onOptionSelect }) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <img src={Frame2ctxtTitle} className="w-5/6" alt="" />

      <img
        onClick={() => handleOptionClick("optiona")}
        className={`${
          selectedOption === "optiona" || selectedOption === null
            ? ""
            : "opacity-50 "
        } mt-24  ${selectedOption === "optiona" ? "w-[85%]" : "w-[80%]"}`}
        id="optiona"
        src={optiona}
        alt=""
      />
      <img
        onClick={() => handleOptionClick("optionb")}
        className={`${
          selectedOption === "optionb" || selectedOption === null
            ? ""
            : "opacity-50"
        }  ${selectedOption === "optionb" ? "w-[85%]" : "w-[80%]"}`}
        id="optionb"
        src={optionb}
        alt=""
      />
      <img
        onClick={() => handleOptionClick("optionc")}
        className={`${
          selectedOption === "optionc" || selectedOption === null
            ? ""
            : "opacity-50"
        }  mb-8 ${selectedOption === "optionc" ? "w-[85%]" : "w-[80%]"}`}
        id="optionc"
        src={optionc}
        alt=""
      />

      <img
        onClick={() => {
          registerTag(selectedOption);

          onOptionSelect(selectedOption);
          selectedOption && onNextClick();
        }}
        id="frame2c-cta"
        className="w-5/6"
        src={Frame2cCTA}
        alt=""
      />
    </div>
  );
}

export default Frame2c;
